.loadingbox {
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
}

.spinner-div {
    transform: translate(-50%, -50%);
}

#spinner-container {
    width: 150px;
    height: 150px;
}

@keyframes animation {
    0% {
        stroke-dasharray: 1 98;
        stroke-dashoffset: -105;
    }

    50% {
        stroke-dasharray: 80 10;
        stroke-dashoffset: -160;
    }

    100% {
        stroke-dasharray: 1 98;
        stroke-dashoffset: -300;
    }
}

#spinner {
    transform-origin: center;
    /* animation-name: animation;
    animation-duration: 1.2s;
    animation-timing-function: cubic-bezier;
    animation-iteration-count: infinite; */
}