.t-suspend-button {
    display: none;
    position: fixed;
    top: 400px;
    right: 0;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    box-shadow: 0px 0px 5px rgba(#000000, .4);
}

.t-suspend-button img {
    height: 70%;
    margin: 20%
}

.t-suspend-button-animate {
    transition-duration: .4s;
}

.t-suspend-button-animate {
    animation: t-suspend-button 1s;

}


/* @keyframes t-suspend-button {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
} */

/* Mobile css */
@media screen and (max-width: 768px) {
    .t-suspend-button {
        display: block;
    }
}