.swiper {
    width: 80% !important;
    height: 80% !important;
    position: relative !important;
    overflow: hidden !important;
    z-index: 1 !important;
    border-radius: 10px !important;
}

@media screen and (max-width: 768px) {
    .swiper {
        height: 100% !important;
        width: 100% !important;
    }
}